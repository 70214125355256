import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import Button from './Button';

class App extends Component {
  state = {
    titolo: "Cliccami..."
  }
  clicca =() =>{
    this.setState({titolo: "Hai cliccato"});
  }
  render(){
    return (
      <div className="App">
        <header className="App-header">
          <a onClick={this.clicca}>
            {this.state.titolo}
          </a>
        </header>
      </div>
    );
    }

}

export default App;
