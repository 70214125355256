import React, { Component } from 'react';
class Button extends Component {
  avvisa = () => {
  	alert('pollo')
  }
  render() {
    // reference as a js object
    return <button onClick={this.avvisa}>Error Button</button>;
  }
}
export default Button;